import { useTranslation } from "react-i18next";
import Modal from "../custom-modal";
import { CloseIcon } from "../../assets/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { cn } from "../../lib/utils";

export type DeliveryTime = { available: boolean; from: string; to: string };

export default function SelectionModal({
    isOpen,
    setIsOpen,
    selectionTitle,
    selectedDay,
    setSelectedDay,
    optionsCount = 4,
    confirmText,
    cart_id,
    flag,
}: {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectionTitle: string;
    selectedDay: number | null;
    setSelectedDay: (day: number | null) => void;
    optionsCount?: number;
    confirmText: string;
    cart_id: string;
    flag: boolean;
}) {
    const { t } = useTranslation();
    const [deliveryTimes, setDeliveryTimes] = useState<DeliveryTime[]>([]);
    const [deliveryTime, setDeliveryTime] = useState<DeliveryTime | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        async function fetchData() {
            try {
                const formData = new FormData();
                formData.append("cart_id", cart_id);
                const response = await axios.post(
                    "/checkout/settings",
                    formData,
                );
                const _data = response.data.data;
                setDeliveryTimes(_data.delivery_times);
            } catch (error) {
                console.error("Error fetching delivery times:", error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [cart_id]);

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="flex flex-col gap-10 sm:-m-4">
                <div className="flex items-center justify-between">
                    <span className="text-2xl font-medium text-gray-900">
                        {selectionTitle}
                    </span>
                    <button
                        className="transition hover:scale-110"
                        onClick={() => setIsOpen(false)}
                    >
                        <CloseIcon />
                    </button>
                </div>
                <span className="text-xl font-medium text-gray-900">
                    Delivery Day
                </span>
                <div className="grid gap-4 md:grid-cols-4">
                    {Array.from(
                        { length: optionsCount },
                        (_, index) => index + 1,
                    ).map((day) => (
                        <button
                            key={day}
                            className={cn("rounded-xl border py-12 text-lg", {
                                "border-primary text-primary":
                                    selectedDay === day,
                                "border-gray-300 text-gray-600":
                                    selectedDay !== day,
                            })}
                            onClick={() => setSelectedDay(day)}
                        >
                            {t(`day-${day}`)}
                        </button>
                    ))}
                </div>
                {flag && (
                    <>
                        <span className="text-xl font-medium text-gray-900">
                            Delivery Time
                        </span>
                        <div className="flex flex-wrap justify-center gap-4">
                            {deliveryTimes.map((_time, idx) => (
                                <button
                                    dir="ltr"
                                    key={idx}
                                    onClick={() => setDeliveryTime(_time)}
                                    disabled={!_time.available}
                                    className={cn(
                                        "flex flex-col items-center gap-3 rounded-lg border border-[rgba(229,229,229,1)] px-3.5 py-3",
                                        {
                                            "border-primary":
                                                _time === deliveryTime,
                                            "opacity-50": !_time.available,
                                        },
                                    )}
                                >
                                    <span className="font-medium capitalize text-[rgba(38,38,38,1)]">
                                        {_time.available
                                            ? t("available")
                                            : t("not-available")}
                                    </span>
                                    <span className="w-[220px] text-sm font-light text-[rgba(64,64,64,1)]">
                                        {_time.from} - {_time.to}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </>
                )}
                {selectedDay && (
                    <div className="flex justify-center">
                        <button
                            onClick={() => setIsOpen(false)}
                            className="flex w-full max-w-[302px] items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-white"
                        >
                            {confirmText}
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}