import React from "react";
import Accordion from "../../accordion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import AttributeCard from "../../attribute-card";
import { cn } from "../../../lib/utils";
import { useTranslation } from "react-i18next";

const CuttingsSection = ({
    attribute,
    pushAttribute,
    cartAttributes,
    data,
}) => {
    const { i18n, t } = useTranslation();
    return (
        <Accordion title={attribute.name} key={attribute.id}>
            <Swiper
                spaceBetween={16}
                slidesPerView={4.2}
                dir={i18n.dir.toString()}
                key={i18n.language + "hero"}
                breakpoints={{
                    640: { slidesPerView: 1, spaceBetween: 8 },
                    768: { slidesPerView: 2, spaceBetween: 12 },
                    1024: { slidesPerView: 3, spaceBetween: 16 },
                    1280: { slidesPerView: 4.2, spaceBetween: 20 },
                }}
            >
                <div className="flex flex-wrap justify-start gap-4">
                    {attribute.values.map((value, idx) => (
                        // <SwiperSlide key={value.id} className="!h-auto">
                            <button
                                className="contents"
                                onClick={() =>
                                    pushAttribute(attribute.id, value.id)
                                }
                            >
                                <AttributeCard
                                    image={value.image}
                                    title={value.value}
                                    subTitle={data.category.name}
                                    className={cn({
                                        "border-primary":
                                            cartAttributes[attribute.id] &&
                                            cartAttributes[attribute.id]
                                                .value_id == value.id,
                                    })}
                                    video={value.vidro}
                                />
                            </button>
                        // {/* </SwiperSlide> */}
                    ))}
                </div>
            </Swiper>
        </Accordion>
    );
};

export default CuttingsSection;
