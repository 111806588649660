import { cn } from "../lib/utils";

export default function AttributeCard({
    className,
    image,
    title,
    subTitle,
    subSubTitle,
    video,
}: {
    className?: string;
    image: string;
    title: string;
    subTitle: string;
    video?: string;
    subSubTitle?: string;
}) {
    return (
        <div
            className={cn(
                "flex h-full w-full max-w-[187px] flex-col items-start rounded-lg border border-gray-200 p-2",
                "sm:w-full md:max-w-[250px]",
                className,
            )}
        >
            <div className="relative w-full">
                <img
                    src={image}
                    alt={title}
                    className="mb-1.5 h-[121px] w-full rounded-md object-cover"
                />
                {video && (
                    <a
                        href={video}
                        target="_blank"
                        rel="noreferrer"
                        className="absolute end-2 top-2 transition hover:scale-105"
                    >
                        <svg
                            width="38"
                            height="39"
                            viewBox="0 0 38 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_d_258_188781)">
                                <path
                                    d="M5 17C5 24.732 11.268 31 19 31C26.732 31 33 24.732 33 17C33 9.26801 26.732 3 19 3C11.268 3 5 9.26801 5 17Z"
                                    fill="#FF0000"
                                />
                                <path
                                    d="M25.65 13.5871C25.475 12.9746 25.0375 12.5371 24.425 12.3621C23.375 12.0996 18.9125 12.0996 18.9125 12.0996C18.9125 12.0996 14.5375 12.0996 13.4 12.3621C12.7875 12.5371 12.35 12.9746 12.175 13.5871C12 14.7246 12 16.9996 12 16.9996C12 16.9996 12 19.2746 12.2625 20.4121C12.4375 21.0246 12.875 21.4621 13.4875 21.6371C14.5375 21.8996 19 21.8996 19 21.8996C19 21.8996 23.375 21.8996 24.5125 21.6371C25.125 21.4621 25.5625 21.0246 25.7375 20.4121C26 19.2746 26 16.9996 26 16.9996C26 16.9996 26 14.7246 25.65 13.5871ZM17.6 19.0996V14.8996L21.275 16.9996L17.6 19.0996Z"
                                    fill="white"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_258_188781"
                                    x="0.130435"
                                    y="0.565217"
                                    width="37.7391"
                                    height="37.7391"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                >
                                    <feFlood
                                        flood-opacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dy="2.43478" />
                                    <feGaussianBlur stdDeviation="2.43478" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0 0.1 0 0 0 0.16 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_258_188781"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_258_188781"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    </a>
                )}
            </div>
            <span className="mb-1 text-start text-sm font-light text-[rgba(115,115,115,1)]">
                {title}
            </span>
            {subSubTitle && (
                <span className="mb-1 text-start text-sm font-medium text-[rgba(23,23,23,1)]">
                    {subSubTitle}
                </span>
            )}
            <span className="text-start font-medium text-[rgba(23,23,23,1)]">
                {subTitle}
            </span>
        </div>
    );
}
