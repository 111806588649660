import axios from "axios";
import { cn } from "../../lib/utils";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import LoadingSpinner from "../../components/loading-spinner";

const UseCoupon = ({
    coupon,
    setCoupon,
    cart_id,
}: {
    coupon: string;
    setCoupon: Dispatch<SetStateAction<string>>;
    cart_id: any;
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const ref = useRef<HTMLInputElement>(null);
    const [usedCoupon, setUsedCoupon] = useState("");
    const [loading, setLoading] = useState(false);

    const handleUseCoupon = async () => {
        setError(false);
        setLoading(true);

        if (coupon) {
            setUsedCoupon("");
            setCoupon("");
            setLoading(false);
            return;
        }

        if (usedCoupon !== "") {
            try {
                const cartIds = Array.isArray(cart_id)
                    ? cart_id
                    : [cart_id.toString()];
                let isPromoCodeValid = false;

                for (const id of cartIds) {
                    const payload = {
                        promocode: usedCoupon,
                        cart_id: Number(id),
                    };
                    try {
                        const resp = await axios.post(
                            "/check/promocode",
                            payload,
                        );
                        if (resp.data.data.exists === 1) {
                            setCoupon(usedCoupon);
                            isPromoCodeValid = true;
                            break;
                        }
                    } catch (err) {
                        console.error(
                            `Promo code validation failed for cart_id ${id}`,
                            err,
                        );
                    }
                }

                if (!isPromoCodeValid) {
                    setError(true);
                }
            } catch (err) {
                console.error("Error applying promo code:", err);
                setError(true);
            }
        }

        setLoading(false);
    };

    return (
        <div>
            <span className="mb-4 block text-2xl font-medium text-[rgba(38,38,38,1)]">
                {t("enter-coupon")}
            </span>
            <div
                className={cn(
                    "flex w-full overflow-hidden rounded-lg border-y border-s border-[rgba(229,229,229,1)] bg-white",
                )}
            >
                <div className="flex flex-1 items-center gap-2 px-5 py-[18px]">
                    <input
                        type="text"
                        ref={ref}
                        className="w-0 flex-1 bg-transparent pb-0.5 text-primary placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                        placeholder={t("enter-coupon")}
                        value={usedCoupon}
                        onChange={(e) => setUsedCoupon(e.target.value)}
                    />
                </div>
                <button
                    onClick={handleUseCoupon}
                    className={cn(
                        "flex w-20 items-center justify-center bg-primary px-3 text-white transition hover:opacity-80 sm:w-40",
                        {
                            "bg-[rgba(240,61,67,1)]": coupon,
                        },
                    )}
                >
                    {loading ? (
                        <LoadingSpinner />
                    ) : coupon ? (
                        t("cancel")
                    ) : (
                        t("use")
                    )}
                </button>
            </div>
            {error && (
                <span className="block px-4 py-1 text-red-500">
                    {t("invalid-coupon")}
                </span>
            )}
        </div>
    );
};

export default UseCoupon;
