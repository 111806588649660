import React from "react";
import { useTranslation } from "react-i18next";
import { PlusIconSmall, MinusIconSmall } from "../../assets/icons";
import LoadingSpinner from "../loading-spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import useUserStore from "../../store/userStore";
import useModalsStore from "../../store/modalsStore";
import HeaderLogo from "../../assets/header-logo";
import { getCurrency } from "../../lib/middleware";

const BottomSection = ({ data, cartAttributes, subProductID }) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();
    const setModalOpen = useModalsStore((state) => state.setModalOpen);
    const setModalContent = useModalsStore((state) => state.setModalContent);
    const setLoginModal = useModalsStore((state) => state.setLoginModalOpen);
    const user = useUserStore((state) => state.user);
    const [quantity, setQuantity] = useState(1);
    const [currency, setCurrency] = useState("");

    const addToCart = async () => {
        if (!user) {
            toast.error(t("messages.login-cart"));
            setLoginModal(true);
            return;
        }
        setLoading(true);
        const attributes = (data.attributes = Object.values(cartAttributes));
        const cartProduct = {
            department_id: data.department.id,
            category_id: data.category.id,
            product_id: data.id,
            sub_product_id: subProductID == 0 ? null : subProductID,
            quantity: quantity,
            shop_id: data.shop?.id || null,
            attributes: attributes,
        };

        try {
            await axios.post("/cart/add", cartProduct);
            toast.success(t("messages.added-to-cart"));
            setModalOpen(true);
            setModalContent({
                confirmText: t("go-to-cart"),
                cancelText: t("continue-shopping"),
                title: t("new-product-added"),
                onCancel() {
                    setModalOpen(false);
                    navigate("/");
                },
                onConfirm() {
                    setModalOpen(false);
                    navigate("/cart");
                },
                icon: <HeaderLogo />,
                content: (
                    <div className="grid grid-cols-2 text-xl font-medium text-primary">
                        <span>{data.name}</span>
                        <span>
                            {+data.price * quantity} {t(currency)}
                        </span>
                    </div>
                ),
            });
        } catch (error) {
            toast.error(t("messages.failed-to-add"));
        }
        setLoading(false);
    };
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        const fetchedCurrency = getCurrency();
        if (fetchedCurrency) {
            setCurrency(String(fetchedCurrency));
        }
    }, []);

    return (
        <div className="mx-auto flex max-w-[852px] flex-col px-4 pb-28 pt-4">
            <div className="flex flex-col gap-y-4">
                <div className="flex items-center justify-between">
                    <span className="text-[22px] font-medium text-[rgba(23,23,23,1)]">
                        {t("quantity")}
                    </span>
                    <div className="flex items-center gap-x-2.5">
                        <button
                            className="rounded-md bg-[rgba(100,140,116,1)] p-2 transition hover:scale-105"
                            onClick={() => {
                                setQuantity((prev) =>
                                    prev > 1 ? prev - 1 : 1,
                                );
                            }}
                        >
                            <MinusIconSmall />
                        </button>
                        <span className="text-lg font-medium text-[rgba(24,24,27,1)]">
                            {quantity}
                        </span>
                        <button
                            className="rounded-md bg-[rgba(100,140,116,1)] p-2 transition hover:scale-105"
                            onClick={() => {
                                setQuantity(quantity + 1);
                            }}
                        >
                            <PlusIconSmall />
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-between">
                    <span className="text-[22px] font-medium text-[rgba(23,23,23,1)]">
                        {t("price")}
                    </span>
                    <div className="text-lg text-[rgba(24,24,27,1)]">
                        {+data.price * quantity} {t(currency)}
                    </div>
                </div>
                <button
                    className="flex items-center justify-center rounded-lg bg-primary py-[18px] text-white transition hover:opacity-80"
                    onClick={addToCart}
                >
                    {loading ? <LoadingSpinner /> : t("add-to-cart")}
                </button>
            </div>
        </div>
    );
};

export default BottomSection;
