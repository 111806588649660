import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Address, getAddresses } from "../../../actions/getAddresses";
import { useNavigate } from "react-router-dom";
import useModalsStore from "../../../store/modalsStore";
import useCartStore, { CartType } from "../../../store/cartStore";
import useUserStore from "../../../store/userStore";
import useSlaughterStore from "../../../store/slaughterStore";
import useGiftsStore from "../../../store/giftsStore";

export default function ChooseAddress({ type = "all" }: { type?: CartType }) {
    const { t } = useTranslation();
    const [addresses, setAddresses] = useState<Address[]>([]);
    const navigate = useNavigate();
    const setMapModalOpen = useModalsStore((state) => state.setMapModalOpen);
    const [address, setAddress] = useState<Address | null>(null);
    const [isChoosingAddress, setIsChoosingAddress] = useState(false);
    const mainAddress = useUserStore((state) => state.mainAddress);
    const setSlaughterAddressId = useSlaughterStore(
        (state) => state.setAddressId,
    );

    const deliveryPoint = useSlaughterStore((state) => state.deliveryPoint);
    const setCartAddressId = useCartStore((state) => state.setAddressId);

    const setGiftsAddressId = useGiftsStore((state) => state.setAddressId);

    const setAddressId =
        type === "sacrifices"
            ? setSlaughterAddressId
            : type === "gifts"
              ? setGiftsAddressId
              : setCartAddressId;

    async function fetchAddresses() {
        try {
            const _addresses = await getAddresses();
            setAddresses(_addresses ?? []);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (deliveryPoint && type === "sacrifices") {
            setAddressId("");
            setAddress(null);
        }
    }, [deliveryPoint]);

    useEffect(() => {
        if (mainAddress) {
            setAddress(mainAddress);
            setAddressId(mainAddress.id.toString());
            setIsChoosingAddress(false);
        }
        fetchAddresses();
    }, []);

    return isChoosingAddress ? (
        <>
            <div className="mb-10 flex flex-col gap-4">
                {addresses.map((add) => (
                    <button
                        key={add.id}
                        className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
                        onClick={() => {
                            setIsChoosingAddress(false);
                            setAddress(add);
                            setAddressId(add.id.toString());
                        }}
                    >
                        <div className="flex w-full items-center gap-2">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                                <path
                                    d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                                    stroke="#292D32"
                                    strokeWidth="1.625"
                                />
                            </svg>
                            <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                                {t("deliver-to")}
                            </span>
                        </div>
                        <span className="ms-[36px] text-[rgba(82,82,82,1)]">
                            {add?.emirate && add?.emirate?.name} -{" "}
                            {add?.region && add?.region?.name} -
                            {t("street-name")} {add?.street_name} -{" "}
                            {t("building-number")} {add?.building_number}
                        </span>
                    </button>
                ))}
                <div className="mx-auto flex w-full max-w-[368px] flex-col gap-4">
                    <button
                        onClick={() => {
                            navigate(
                                "/add-new-address?redirectTo=/checkout/single",
                            );
                            setMapModalOpen(true);
                        }}
                        className="flex items-center justify-center rounded-lg border border-primary bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                    >
                        {t("add-new-address")}
                    </button>
                </div>
            </div>
        </>
    ) : address ? (
        <button
            key={address.id}
            className="flex flex-col rounded-lg border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
            onClick={() => {
                setIsChoosingAddress(true);
            }}
        >
            <div className="flex w-full items-center gap-2">
                <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                        stroke="#292D32"
                        strokeWidth="1.625"
                    />
                    <path
                        d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                        stroke="#292D32"
                        strokeWidth="1.625"
                    />
                </svg>
                <span className="mb-0.5 text-xl font-medium text-[rgba(23,23,23,1)]">
                    {t("deliver-to")}
                </span>
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ms-auto rtl:rotate-180"
                >
                    <path
                        d="M11.0367 21.7233L19.25 13.4867L11.0367 5.25"
                        stroke="#525252"
                        strokeWidth="2.625"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
            <span className="ms-[36px] text-[rgba(82,82,82,1)]">
                {address?.emirate?.name} - {address?.region?.name} -
                {t("street-name")} {address?.street_name} -{" "}
                {t("building-number")} {address?.building_number}
            </span>
        </button>
    ) : (
        <button
            onClick={() => setIsChoosingAddress(true)}
            className="flex gap-2 rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4 transition hover:opacity-80"
        >
            <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mt-0.5"
            >
                <path
                    d="M13.0001 14.5491C14.8668 14.5491 16.3801 13.0358 16.3801 11.1691C16.3801 9.30234 14.8668 7.78906 13.0001 7.78906C11.1334 7.78906 9.62012 9.30234 9.62012 11.1691C9.62012 13.0358 11.1334 14.5491 13.0001 14.5491Z"
                    stroke="#292D32"
                    strokeWidth="1.625"
                />
                <path
                    d="M3.92168 9.19685C6.05584 -0.184815 19.955 -0.173982 22.0783 9.20769C23.3242 14.711 19.9008 19.3694 16.9 22.251C14.7225 24.3527 11.2775 24.3527 9.08918 22.251C6.09918 19.3694 2.67584 14.7002 3.92168 9.19685Z"
                    stroke="#292D32"
                    strokeWidth="1.625"
                />
            </svg>
            <span className="text-xl font-medium text-[rgba(23,23,23,1)]">
                {t("choose-address")}
            </span>
        </button>
    );
}
