import { useState } from "react";
import Logo from "../../assets/logo";
import { cn, md5Encrypt } from "../../lib/utils";
import { Country } from "../../types/user";
import SocialLogin from "./social-login";
import useUserStore from "../../store/userStore";
import { toast } from "react-toastify";
import LoadingSpinner from "../loading-spinner";
import { useTranslation } from "react-i18next";
import useModalsStore from "../../store/modalsStore";
import SelectCountryCode from "../modals/select-country-code";
import axios from "axios";
import { setSession, setToken, setCountry } from "../../lib/middleware";

export const RegisterForm = () => {
    const {
        isRegisterModalOpen,
        setLoginModalOpen,
        setRegisterModalOpen,
        setOtpModalOpen,
        setForgotPassword,
    } = useModalsStore();

    const [isCountrySelecting, setIsCountrySelecting] = useState(false);
    const [isCountryCodeSelecting, setIsCountryCodeSelecting] = useState(false);

    const [cou, setCou] = useState<{
        name: string;
        code: Country;
        icon: string;
    }>({
        name: "United Arab Emirates",
        code: "uae",
        icon: "/zabehatyIcons/uae.png",
    });

    const { t } = useTranslation();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobile, setMobile] = useState("");
    const [countryCode, setCountryCode] = useState<string>("971");
    const [password, setPassword] = useState("");
    const [visiblePassword, setVisiblePassword] = useState(false);

    const [error, setError] = useState<1 | 2 | 3 | 4 | null>(null);
    const [loading, setLoading] = useState(false);

    const setUser = useUserStore((state) => state.setUser);
    const handleRegister = async () => {
        setLoading(true);
        setError(null);

        if (!firstName) {
            setError(1);
            setLoading(false);

            return;
        }

        if (!lastName) {
            setError(2);
            setLoading(false);
            return;
        }

        if (!mobile) {
            setError(3);
            setLoading(false);
            return;
        }

        if (!password) {
            setError(4);
            setLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("mobile", mobile);
        formData.append("password", password);
        formData.append("country_code", countryCode);
        formData.append("device_type", "web");

        try {
            const response = await axios.post("/user/register", formData, {
                headers: {
                    "otp-token": md5Encrypt(mobile),
                },
            });
            const data = response.data;
            setUser(data.data);
            setSession();
            toast.success(t("messages.register-success"));
            setToken(data.data.token);
            setRegisterModalOpen(false);
            setForgotPassword(false);
            setOtpModalOpen(true);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            toast.error(t("messages.mobile-exists"));
        }
    };

    return (
        <>
            <div
                className={cn("flex flex-col", {
                    hidden: !isRegisterModalOpen,
                })}
            >
                <div className="mb-4 flex">
                    <button
                        className={cn(
                            "transition hover:scale-125 rtl:rotate-180",
                            {
                                hidden:
                                    !isCountrySelecting &&
                                    !isCountryCodeSelecting,
                            },
                        )}
                        onClick={() => {
                            setIsCountrySelecting(false);
                            setIsCountryCodeSelecting(false);
                        }}
                    >
                        <svg
                            width="40"
                            height="41"
                            viewBox="0 0 40 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g filter="url(#filter0_d_258_156948)">
                                <path
                                    d="M22.5 27.7148L15 19.5898L22.5 11.4648"
                                    stroke="#262626"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <filter
                                    id="filter0_d_258_156948"
                                    x="-5"
                                    y="0.214844"
                                    width="50"
                                    height="50"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                >
                                    <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                        in="SourceAlpha"
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha"
                                    />
                                    <feOffset dy="5" />
                                    <feGaussianBlur stdDeviation="2.5" />
                                    <feComposite
                                        in2="hardAlpha"
                                        operator="out"
                                    />
                                    <feColorMatrix
                                        type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in2="BackgroundImageFix"
                                        result="effect1_dropShadow_258_156948"
                                    />
                                    <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_dropShadow_258_156948"
                                        result="shape"
                                    />
                                </filter>
                            </defs>
                        </svg>
                    </button>
                    <button
                        className="ms-auto transition hover:scale-125"
                        onClick={() => setRegisterModalOpen(false)}
                    >
                        <svg
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.07975 8.43587C8.70459 7.81103 9.71765 7.81103 10.3425 8.43587L15.9993 14.0927L21.6562 8.43587C22.281 7.81103 23.2941 7.81103 23.9189 8.43587C24.5438 9.06071 24.5438 10.0738 23.9189 10.6986L18.2621 16.3555L23.9189 22.0123C24.5438 22.6372 24.5438 23.6502 23.9189 24.2751C23.2941 24.8999 22.281 24.8999 21.6562 24.2751L15.9993 18.6182L10.3425 24.2751C9.71765 24.8999 8.70459 24.8999 8.07975 24.2751C7.45491 23.6502 7.45491 22.6372 8.07975 22.0123L13.7366 16.3555L8.07975 10.6986C7.45491 10.0738 7.45491 9.06071 8.07975 8.43587Z"
                                fill="#262626"
                            />
                        </svg>
                    </button>
                </div>

                {!isCountrySelecting && !isCountryCodeSelecting && (
                    <>
                        <div className="mb-8 flex justify-center">
                            <Logo />
                        </div>
                        <span className="mb-8 text-center text-2xl font-medium text-foreground">
                            {t("modals.register.title")}
                        </span>
                        <div className="flex flex-col items-center gap-y-3">
                            <button
                                className="flex w-full items-center gap-x-3 rounded-lg border px-4 py-[18px]"
                                onClick={() => setIsCountrySelecting(true)}
                            >
                                <svg
                                    width="18"
                                    height="19"
                                    viewBox="0 0 18 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.00016 10.5745C10.2925 10.5745 11.3402 9.52688 11.3402 8.23453C11.3402 6.94219 10.2925 5.89453 9.00016 5.89453C7.70781 5.89453 6.66016 6.94219 6.66016 8.23453C6.66016 9.52688 7.70781 10.5745 9.00016 10.5745Z"
                                        stroke="#525252"
                                    />
                                    <path
                                        d="M2.71527 6.8675C4.19277 0.372502 13.8153 0.380002 15.2853 6.875C16.1478 10.685 13.7778 13.91 11.7003 15.905C10.1928 17.36 7.80777 17.36 6.29277 15.905C4.22277 13.91 1.85277 10.6775 2.71527 6.8675Z"
                                        stroke="#525252"
                                    />
                                </svg>
                                <span className="flex-1 text-start font-light text-foreground">
                                    {t("countries." + cou.code)}
                                </span>
                                <img
                                    src={cou.icon}
                                    alt={cou.name}
                                    className="w-7 object-cover"
                                />
                            </button>
                            <div className="w-full">
                                <div className="flex items-center gap-x-3 rounded-lg border px-4 py-[18px]">
                                    <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.11992 8.6525C9.04492 8.645 8.95492 8.645 8.87242 8.6525C7.08742 8.5925 5.66992 7.13 5.66992 5.33C5.66992 3.4925 7.15492 2 8.99992 2C10.8374 2 12.3299 3.4925 12.3299 5.33C12.3224 7.13 10.9049 8.5925 9.11992 8.6525Z"
                                            stroke="#525252"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.37004 11.42C3.55504 12.635 3.55504 14.615 5.37004 15.8225C7.43254 17.2025 10.815 17.2025 12.8775 15.8225C14.6925 14.6075 14.6925 12.6275 12.8775 11.42C10.8225 10.0475 7.44004 10.0475 5.37004 11.42Z"
                                            stroke="#525252"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={firstName}
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                        placeholder={t("modals.first-name")}
                                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                                    />
                                </div>
                                {error === 1 && (
                                    <p className="font-light text-red-500">
                                        {t("modals.first-name")}{" "}
                                        {t("modals.errors.is-required")}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <div className="flex items-center gap-x-3 rounded-lg border px-4 py-[18px]">
                                    <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.11992 8.6525C9.04492 8.645 8.95492 8.645 8.87242 8.6525C7.08742 8.5925 5.66992 7.13 5.66992 5.33C5.66992 3.4925 7.15492 2 8.99992 2C10.8374 2 12.3299 3.4925 12.3299 5.33C12.3224 7.13 10.9049 8.5925 9.11992 8.6525Z"
                                            stroke="#525252"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M5.37004 11.42C3.55504 12.635 3.55504 14.615 5.37004 15.8225C7.43254 17.2025 10.815 17.2025 12.8775 15.8225C14.6925 14.6075 14.6925 12.6275 12.8775 11.42C10.8225 10.0475 7.44004 10.0475 5.37004 11.42Z"
                                            stroke="#525252"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={lastName}
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                        placeholder={t("modals.last-name")}
                                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                                    />
                                </div>
                                {error === 2 && (
                                    <p className="font-light text-red-500">
                                        {t("modals.last-name")}{" "}
                                        {t("modals.errors.is-required")}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <div className="flex items-center rounded-lg border px-4 py-[18px]">
                                    <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        className="me-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.4775 14.2475C16.4775 14.5175 16.4175 14.795 16.29 15.065C16.1625 15.335 15.9975 15.59 15.78 15.83C15.4125 16.235 15.0075 16.5275 14.55 16.715C14.1 16.9025 13.6125 17 13.0875 17C12.3225 17 11.505 16.82 10.6425 16.4525C9.78 16.085 8.9175 15.59 8.0625 14.9675C7.2 14.3375 6.3825 13.64 5.6025 12.8675C4.83 12.0875 4.1325 11.27 3.51 10.415C2.895 9.56 2.4 8.705 2.04 7.8575C1.68 7.0025 1.5 6.185 1.5 5.405C1.5 4.895 1.59 4.4075 1.77 3.9575C1.95 3.5 2.235 3.08 2.6325 2.705C3.1125 2.2325 3.6375 2 4.1925 2C4.4025 2 4.6125 2.045 4.8 2.135C4.995 2.225 5.1675 2.36 5.3025 2.555L7.0425 5.0075C7.1775 5.195 7.275 5.3675 7.3425 5.5325C7.41 5.69 7.4475 5.8475 7.4475 5.99C7.4475 6.17 7.395 6.35 7.29 6.5225C7.1925 6.695 7.05 6.875 6.87 7.055L6.3 7.6475C6.2175 7.73 6.18 7.8275 6.18 7.9475C6.18 8.0075 6.1875 8.06 6.2025 8.12C6.225 8.18 6.2475 8.225 6.2625 8.27C6.3975 8.5175 6.63 8.84 6.96 9.23C7.2975 9.62 7.6575 10.0175 8.0475 10.415C8.4525 10.8125 8.8425 11.18 9.24 11.5175C9.63 11.8475 9.9525 12.0725 10.2075 12.2075C10.245 12.2225 10.29 12.245 10.3425 12.2675C10.4025 12.29 10.4625 12.2975 10.53 12.2975C10.6575 12.2975 10.755 12.2525 10.8375 12.17L11.4075 11.6075C11.595 11.42 11.775 11.2775 11.9475 11.1875C12.12 11.0825 12.2925 11.03 12.48 11.03C12.6225 11.03 12.7725 11.06 12.9375 11.1275C13.1025 11.195 13.275 11.2925 13.4625 11.42L15.945 13.1825C16.14 13.3175 16.275 13.475 16.3575 13.6625C16.4325 13.85 16.4775 14.0375 16.4775 14.2475Z"
                                            stroke="#525252"
                                            strokeMiterlimit="10"
                                        />
                                    </svg>

                                    <input
                                        type="text"
                                        name="mobile"
                                        value={mobile}
                                        onChange={(e) =>
                                            setMobile(e.target.value)
                                        }
                                        placeholder={t("modals.mobile")}
                                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                                    />

                                    <button
                                        className="flex items-center transition hover:opacity-80"
                                        onClick={() =>
                                            setIsCountryCodeSelecting(true)
                                        }
                                    >
                                        +{countryCode}
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g clipPath="url(#clip0_258_154458)">
                                                <path
                                                    d="M4.66516 5.86704L6.10796 7.55893C6.32521 7.81369 6.67616 7.81369 6.89342 7.55893L8.33622 5.86704C8.68717 5.4555 8.43649 4.75 7.9407 4.75H5.0551C4.55931 4.75 4.31421 5.4555 4.66516 5.86704Z"
                                                    fill="#525252"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_258_154458">
                                                    <rect
                                                        width="11.5"
                                                        height="11.5"
                                                        fill="white"
                                                        transform="translate(0.5 0.5)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                </div>
                                {error === 3 && (
                                    <p className="font-light text-red-500">
                                        {t("modals.mobile")}{" "}
                                        {t("modals.errors.is-required-h")}
                                    </p>
                                )}
                            </div>
                            <div className="w-full">
                                <div className="flex items-center gap-x-3 rounded-lg border px-4 py-[18px]">
                                    <svg
                                        width="18"
                                        height="19"
                                        viewBox="0 0 18 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clipPath="url(#clip0_258_154462)">
                                            <path
                                                d="M15.1437 8.24037C14.7032 7.79994 14.1175 7.5575 13.4946 7.5575H13.3259V5.23944C13.3259 2.87159 11.3996 0.945312 9.03189 0.945312H8.87313C6.50542 0.945312 4.579 2.87159 4.579 5.23944V7.55736H4.41025C3.78742 7.55736 3.20172 7.79994 2.76114 8.24037C2.3207 8.68095 2.07812 9.26665 2.07812 9.88962V15.7188C2.07812 16.3418 2.3207 16.9275 2.76114 17.3678C3.20158 17.8084 3.78728 18.0509 4.41025 18.0509H13.4946C14.1176 18.0509 14.7033 17.8084 15.1439 17.3678C15.5843 16.9273 15.8269 16.3416 15.8269 15.7188V9.88962C15.8269 9.2668 15.5843 8.68109 15.1437 8.24037ZM5.704 5.23944C5.704 3.49203 7.12558 2.07031 8.87313 2.07031H9.03189C10.7793 2.07031 12.2009 3.49203 12.2009 5.23944V7.55736H5.704V5.23944ZM14.7019 15.7188C14.7019 16.0365 14.5729 16.3475 14.3484 16.5723C14.1203 16.8004 13.8171 16.9259 13.4946 16.9259H4.41025C4.0878 16.9259 3.78475 16.8004 3.55652 16.5721C3.32856 16.3443 3.20298 16.0413 3.20298 15.7188V9.88962C3.20298 9.56717 3.32856 9.26384 3.55652 9.03589C3.78461 8.80794 4.08766 8.68236 4.41011 8.68236H13.4945C13.8169 8.68236 14.1201 8.80794 14.3481 9.03575C14.5762 9.26398 14.7017 9.56717 14.7017 9.88962L14.7019 15.7188Z"
                                                fill="#525252"
                                            />
                                            <path
                                                d="M8.95312 11.25C8.64248 11.25 8.39062 11.5019 8.39062 11.8125V13.7942C8.39062 14.1048 8.64248 14.3567 8.95312 14.3567C9.26377 14.3567 9.51562 14.1048 9.51562 13.7942V11.8125C9.51562 11.5019 9.26377 11.25 8.95312 11.25Z"
                                                fill="#525252"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_258_154462">
                                                <rect
                                                    width="18"
                                                    height="18"
                                                    fill="white"
                                                    transform="translate(0 0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <input
                                        type={
                                            visiblePassword
                                                ? "text"
                                                : "password"
                                        }
                                        name="password"
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder={t("modals.password")}
                                        className="flex-1 font-light text-foreground placeholder:text-[rgba(212,212,212,1)] focus:outline-none"
                                    />
                                    <button
                                        className="transition hover:opacity-80"
                                        onClick={() =>
                                            setVisiblePassword(!visiblePassword)
                                        }
                                    >
                                        <svg
                                            width="18"
                                            height="19"
                                            viewBox="0 0 18 19"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M11.6849 9.50141C11.6849 10.9864 10.4849 12.1864 8.99994 12.1864C7.51494 12.1864 6.31494 10.9864 6.31494 9.50141C6.31494 8.01641 7.51494 6.81641 8.99994 6.81641C10.4849 6.81641 11.6849 8.01641 11.6849 9.50141Z"
                                                stroke="#525252"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M8.99988 15.7016C11.6474 15.7016 14.1149 14.1416 15.8324 11.4416C16.5074 10.3841 16.5074 8.60656 15.8324 7.54906C14.1149 4.84906 11.6474 3.28906 8.99988 3.28906C6.35238 3.28906 3.88488 4.84906 2.16738 7.54906C1.49238 8.60656 1.49238 10.3841 2.16738 11.4416C3.88488 14.1416 6.35238 15.7016 8.99988 15.7016Z"
                                                stroke="#525252"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                {error === 4 && (
                                    <p className="font-light text-red-500">
                                        {t("modals.password")}{" "}
                                        {t("modals.errors.is-required-h")}
                                    </p>
                                )}
                            </div>
                            <button
                                onClick={handleRegister}
                                className="flex w-full items-center justify-center rounded-lg bg-primary px-4 py-[18px] text-white transition hover:opacity-80"
                            >
                                {loading ? (
                                    <LoadingSpinner />
                                ) : (
                                    t("modals.register-btn")
                                )}
                            </button>

                            <div className="my-4 font-light text-[rgba(115,115,115,1)]">
                                {t("modals.register.already-have-account")}{" "}
                                <button
                                    className="text-[rgba(15,23,42,1)] transition hover:opacity-80"
                                    onClick={() => {
                                        setRegisterModalOpen(false);
                                        setLoginModalOpen(true);
                                    }}
                                >
                                    {t("modals.register.login")}
                                </button>
                            </div>
                            <div className="flex w-full items-center gap-x-6">
                                <div className="h-[1px] flex-1 bg-[rgba(229,229,229,1)]"></div>
                                <span className="font-light text-[rgba(106,112,124,1)]">
                                    {t("modals.login-with")}
                                </span>
                                <div className="h-[1px] w-auto flex-1 bg-[rgba(229,229,229,1)]" />
                            </div>
                            <SocialLogin />
                        </div>
                    </>
                )}
                {isCountrySelecting && (
                    <>
                        <span className="mb-8 text-2xl font-medium text-foreground">
                            {t("modals.select-country")}
                        </span>
                        <button
                            className={cn(
                                "mb-4 flex items-center justify-between rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] font-light transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        cou.code === "uae",
                                },
                            )}
                            onClick={() => {
                                setCountry("uae");
                                setCountryCode("971");
                                setSession();
                                setCou({
                                    code: "uae",
                                    name: "United Arab Emirates",
                                    icon: "/zabehatyIcons/uae.png",
                                });
                                setIsCountrySelecting(false);
                            }}
                        >
                            {t("countries.uae")}
                            <img
                                src="/zabehatyIcons/uae.png"
                                alt="uae"
                                className="w-7 object-cover"
                            />
                        </button>
                        <button
                            className={cn(
                                "mb-4 flex items-center justify-between rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] font-light transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        cou.code === "om",
                                },
                            )}
                            onClick={() => {
                                setCountry("om");
                                setCountryCode("968");
                                setSession();
                                setCou({
                                    code: "om",
                                    name: "Oman",
                                    icon: "/zabehatyIcons/om.png",
                                });
                                setIsCountrySelecting(false);
                            }}
                        >
                            <span>{t("countries.om")}</span>
                            <img
                                src="/zabehatyIcons/om.png"
                                alt="om"
                                className="w-7 object-cover"
                            />
                        </button>
                        <button
                            className={cn(
                                "mb-[52px] flex items-center justify-between rounded-xl border-[0.5px] border-[rgba(229,229,229,0.9)] px-4 py-[18px] font-light transition hover:opacity-80",
                                {
                                    "border border-primary shadow-[0px_2px_4px_0px_rgba(74,74,74,0.1)]":
                                        cou.code === "ksa",
                                },
                            )}
                            onClick={() => {
                                setCountry("ksa");
                                setCountryCode("966");
                                setSession();
                                setCou({
                                    code: "ksa",
                                    name: "Saudi Arabia",
                                    icon: "/zabehatyIcons/ksa.png",
                                });
                                setIsCountrySelecting(false);
                            }}
                        >
                            <span>{t("countries.ksa")}</span>
                            <img
                                src="/zabehatyIcons/ksa.png"
                                alt="ksa"
                                className="w-7 object-cover"
                            />
                        </button>
                    </>
                )}
                {isCountryCodeSelecting && (
                    <SelectCountryCode
                        countryCode={countryCode}
                        setCountryCode={setCountryCode}
                        setIsCountryCodeSelecting={setIsCountryCodeSelecting}
                    />
                )}
            </div>
        </>
    );
};
