import { Link, useParams } from "react-router-dom";
import { getCategory } from "../actions/getCategory";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import ShopsSection from "../components/home/shops-section";
import ProductsSection from "../components/home/products-section";
import LoadingScreen from "../components/loading-screen";
import { useState } from "react";

export default function Category() {
    const { categoryId } = useParams();
    const [subCategoryIndex, setSubCategoryIndex] = useState(0);

    const { t } = useTranslation();
    const { data, isLoading } = useQuery({
        queryKey: ["global", "category", { categoryId }],
        queryFn: () =>
            getCategory({
                categoryId: categoryId || "",
            }),
    });

    if (isLoading) return <LoadingScreen />;
    if (!data) return null;
    return (
        <div className="container flex flex-col gap-y-10 pb-28 pt-14 xl:gap-y-20">
            <h2 className="text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {data.name}
            </h2>
            {data.sub_categories.length > 0 && (
                <div className="flex flex-wrap justify-center gap-6">
                    {data.sub_categories?.map((subCategory, idx) => (
                        <button
                            onClick={() => setSubCategoryIndex(idx)}
                            key={subCategory.id}
                            className="flex flex-col items-center"
                        >
                            <img
                                src={subCategory.image}
                                alt={subCategory.name}
                                className="mb-3 size-[80px] rounded-full object-cover xl:mb-6 xl:size-[146px]"
                            />
                            <span className="text-[26px] text-[rgba(64,64,64,1)]">
                                {subCategory.name}
                            </span>
                        </button>
                    ))}
                </div>
            )}

            {data.sub_categories.length > 0 &&
                (data.sub_categories[subCategoryIndex]?.sub_categories
                    ?.length ?? 0) > 0 && (
                    <div>
                        <h3 className="mb-3 text-2xl font-medium text-[rgba(23,23,23,1)] xl:mb-6 xl:text-[28px]">
                            <span className="ltr:hidden">{t("species")}</span>{" "}
                            {data.sub_categories[subCategoryIndex].name}{" "}
                            <span className="rtl:hidden">{t("species")}</span>
                        </h3>
                        <div className="flex flex-wrap gap-6">
                            {data.sub_categories[
                                subCategoryIndex
                            ]?.sub_categories?.map((subCategory) => (
                                <Link
                                    to={`/product/${subCategory.product_id}`}
                                    key={subCategory.id}
                                    className="flex flex-col items-center rounded-[11px] bg-[rgba(207,219,211,1)] p-3 shadow-[0px_5.5px_5.5px_0px_rgba(182,182,182,0.15)] xl:px-4 xl:py-4"
                                >
                                    <img
                                        src={subCategory.image}
                                        alt={subCategory.name}
                                        className="mb-0.5 size-[90px] object-cover xl:size-[146px]"
                                    />
                                    <span className="text-xl text-[rgba(23,23,23,1)]">
                                        {subCategory.name}
                                    </span>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            {data.products.length > 0 && (
                <ProductsSection
                    products={data.products}
                    title={t("products")}
                />
            )}
            {data.shops.length > 0 && (
                <ShopsSection
                    shops={data.shops}
                    title={data.shop_section_name}
                />
            )}
        </div>
    );
}
