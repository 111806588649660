import axios from "axios";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../lib/middleware";

export default function QuantityPrice({
    quantity: initialQuantity,
    price,
    cart_detail_id,
    setTotalCost,
}: {
    quantity: number;
    price: number;
    cart_detail_id: number;
    setTotalCost: (totalCost: number) => void;
}) {
    const [quantity, setQuantity] = useState(initialQuantity);
    const { t } = useTranslation();
    const [currency, setCurrency] = useState("");
    useEffect(() => {
        const fetchedCurrency = getCurrency();
        if (fetchedCurrency) {
            setCurrency(String(fetchedCurrency));
        }
    }, []);
    const updateProduct = async (quantity: number) => {
        try {
            const formData = new FormData();
            formData.append("cart_detail_id", cart_detail_id.toString());
            formData.append("quantity", quantity.toString());
            const { data } = await axios.post("/cart/edit", formData);
            setTotalCost(
                data.data.reduce(
                    (acc: number, item: any) => acc + +item.total,
                    0,
                ),
            );
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="flex items-center justify-between">
            <span>
                +{quantity * price} {t(currency)}
            </span>

            <div className="flex items-center gap-x-2.5">
                <button
                    className="rounded-md bg-[rgba(100,140,116,1)] p-2 transition hover:scale-105"
                    onClick={() => {
                        setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
                        updateProduct(quantity > 1 ? quantity - 1 : 1);
                    }}
                >
                    <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.22196 8.65486H12.7775C13.4154 8.65486 13.9331 8.13717 13.9331 7.49931C13.9331 6.86144 13.4154 6.34375 12.7775 6.34375H1.22196C0.584095 6.34375 0.0664062 6.86144 0.0664062 7.49931C0.0664062 8.13717 0.584095 8.65486 1.22196 8.65486Z"
                            fill="white"
                        />
                    </svg>
                </button>
                <span className="text-lg font-medium text-[rgba(24,24,27,1)]">
                    {quantity}
                </span>
                <button
                    className="rounded-md bg-[rgba(100,140,116,1)] p-2 transition hover:scale-105"
                    onClick={() => {
                        setQuantity(quantity + 1);
                        updateProduct(quantity + 1);
                    }}
                >
                    <svg
                        width="14"
                        height="15"
                        viewBox="0 0 14 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.22196 8.65486H12.7775C13.4154 8.65486 13.9331 8.13717 13.9331 7.49931C13.9331 6.86144 13.4154 6.34375 12.7775 6.34375H1.22196C0.584095 6.34375 0.0664062 6.86144 0.0664062 7.49931C0.0664062 8.13717 0.584095 8.65486 1.22196 8.65486Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.15095 13.2775V1.72196C8.15095 1.0841 7.63327 0.566406 6.9954 0.566406C6.35753 0.566406 5.83984 1.0841 5.83984 1.72196V13.2775C5.83984 13.9154 6.35753 14.4331 6.9954 14.4331C7.63327 14.4331 8.15095 13.9154 8.15095 13.2775Z"
                            fill="white"
                        />
                    </svg>
                </button>
            </div>
        </div>
    );
}
