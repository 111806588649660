import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getCart } from "../actions/getCart";
import LoadingScreen from "../components/loading-screen";
import { useEffect, useState } from "react";
import { toArabic } from "../lib/utils";
import LoadingSpinner from "../components/loading-spinner";
import axios from "axios";
import ChooseAddress from "../components/checkout/checkout-all/choose-address";
import useCartStore from "../store/cartStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UsePoints from "../components/checkout/UsePoints";
import UseBalance from "../components/checkout/UseBalance";
import UseCoupon from "../components/checkout/UseCoupon";
import Notes from "../components/checkout/Notes";
import DriverTip from "../components/checkout/DriverTip";
import CheckoutItem from "../components/checkout/checkout-all/checkout-item";
import PaymentMethod from "../components/checkout/PaymentMethod";
import { getCurrency } from "../lib/middleware";

export type CheckoutDetails = {
    total: number;
    discount: number;
    dicountedTotal: number;
    subtotal: number;
    points: number;
    tax: number;
    driverTips: number;
    delivery: number;
};

export default function CheckoutDonations() {
    const { t } = useTranslation();
    const { data, isFetching } = useQuery({
        queryKey: ["global", "request-review"],
        queryFn: async () => {
            const cartItems = await getCart();
            const filteredCartItems = cartItems?.filter(
                (item) => item.department.id === 3,
            );
            return filteredCartItems ? filteredCartItems[0] : undefined;
        },
    });

    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<string[]>([]);

    const [checkoutDetails, setCheckoutDetails] = useState<CheckoutDetails>({
        total: 0,
        discount: 0,
        dicountedTotal: 0,
        subtotal: 0,
        points: 0,
        tax: 0,
        driverTips: 0,
        delivery: 0,
    });
    const [notes, setNotes] = useState("");
    const [driverTip, setDriverTip] = useState(0);
    const [usePoints, setUsePoints] = useState(false);
    const [useBalance, setUseBalance] = useState(false);
    const [coupon, setCoupon] = useState("");
    const { address_id, collection } = useCartStore((state) => state);
    const [shippingMethodId, setShippingMethodId] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");

    const [currency, setCurrency] = useState("");

    useEffect(
        () =>
            setShippingMethodId(
                collection.get(`${data?.id}`)?.shippingMethodId!,
            ),
        [collection, data],
    );

    useEffect(() => {
        const fetchedCurrency = getCurrency();
        if (fetchedCurrency) {
            setCurrency(String(fetchedCurrency));
        }
    }, []);

    useEffect(() => {
        async function fetchSettings() {
            if (!data) return;
            try {
                const response = await axios.post("/checkout/settings", {
                    cart_id: data.id,
                });
                const _data = response.data.data;
                setPaymentMethods(_data.payments);
            } catch (error) {
                console.error(error);
            }
        }
        if (data) {
            fetchSettings();
        }
    }, [data]);
    const navigate = useNavigate();

    const checkout = async () => {
        toast.error(t("use-app"));
        return;

        /*if (!data) return;
        setLoading(true);
        if (!address_id) {
            toast.error(t("choose-address-error"));
            setLoading(false);
            return;
        } else if (!paymentMethod || paymentMethod=='0') {
            toast.error(t("select-payment-error"));
            setLoading(false);
            return;
        } else if (!shippingMethodId) {
            toast.error(t("select-deivery-error"));
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post("/orders/create", {
                cart_id: data.id,
                payment_method: paymentMethod,
                driver_tip: driverTip,
                notes,
                shop_id: data?.shop?.id || 0,
                redeem_points: usePoints,
                use_balance: useBalance,
                promocode: coupon,
                time_from: collection.get(`${data.id}`)?.time_from!,
                time_to: collection.get(`${data.id}`)?.time_to!,
                date: collection.get(`${data.id}`)?.date!,
                address_id: address_id,
            });
            if (paymentMethod !== "1")
                window.open(
                    `${process.env.REACT_APP_PAYMENT_URL}?order_id=${response.data.data.id}`,
                    "_self",
                );
            else {
                toast.success(t("order-success"));
                navigate("/");
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        */
    };

    const handleCheckoutDetails = async () => {
        if (!data) return;
        try {
            const formData = new FormData();
            formData.append("cart_id", data.id.toString());
            formData.append("redeem_points", usePoints ? "1" : "0");
            formData.append("use_balance", useBalance ? "1" : "0");
            formData.append("driver_tips", driverTip.toString());
            formData.append("promocode", coupon);
            formData.append("payment_method", paymentMethod);
            formData.append("notes", notes);
            formData.append("address_id", address_id.toString());
            shippingMethodId &&
                formData.append("shipping_method_id", shippingMethodId);

            const response = await axios.post("/checkout", formData);
            const _data = response.data.data;
            setCheckoutDetails({
                total: +_data.total,
                discount: +_data.discount,
                dicountedTotal: +_data.discount_total,
                subtotal: +_data.subtotal,
                points: +_data.points,
                tax: +_data.tax,
                driverTips: +_data.driver_tips,
                delivery: +_data.delivery,
            });
        } catch {
            console.error("error");
        }
    };

    useEffect(() => {
        handleCheckoutDetails();
    }, [
        usePoints,
        useBalance,
        driverTip,
        coupon,
        data,
        shippingMethodId,
        paymentMethod,
        address_id,
    ]);

    if (isFetching) return <LoadingScreen />;
    if (!data) return null;

    return (
        <div className="container flex flex-col pb-28 pt-14">
            <h2 className="mb-10 text-center text-[40px] font-medium text-[rgba(23,23,23,1)]">
                {t("cart")}
            </h2>

            <div className="grid gap-20 xl:grid-cols-3">
                <div className="flex flex-col gap-6 xl:col-span-2">
                    <ChooseAddress />
                    <CheckoutItem cartItem={data} />
                    <UsePoints
                        usePoints={usePoints}
                        setUsePoints={setUsePoints}
                    />
                    <UseBalance
                        useBalance={useBalance}
                        setUseBalance={setUseBalance}
                    />
                    <UseCoupon
                        coupon={coupon}
                        setCoupon={setCoupon}
                        cart_id={data.id}
                    />
                </div>
                <div className="flex flex-col gap-6">
                    <PaymentMethod
                        paymentMethods={paymentMethods}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                    />
                    <Notes notes={notes} setNotes={setNotes} />
                    <DriverTip
                        driverTip={driverTip}
                        setDriverTip={setDriverTip}
                    />
                    <div className="flex flex-col gap-[18px] rounded-xl border border-[rgba(229,229,229,1)] bg-white p-4 leading-none text-[rgba(64,64,64,1)]">
                        <div className="flex items-center justify-between">
                            <span>{t("order-cost")}</span>
                            <span className="font-medium">
                                +{toArabic(checkoutDetails.subtotal.toFixed(2))}{" "}
                                {t(currency)}
                            </span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span>{t("delivery")}</span>
                            <span className="font-medium">
                                +{toArabic(checkoutDetails.delivery.toFixed(2))}{" "}
                                {t(currency)}
                            </span>
                        </div>
                        <div className="flex items-center justify-between">
                            <span>{t("added-tax")}</span>
                            <span className="font-medium">
                                +{toArabic(checkoutDetails.tax.toFixed(2))}{" "}
                                {t(currency)}
                            </span>
                        </div>
                        <div className="flex items-center justify-between text-[rgba(255,75,75,1)]">
                            <span>{t("discount")}</span>
                            <span className="font-medium">
                                -{toArabic(checkoutDetails.discount.toFixed(2))}{" "}
                                {t(currency)}
                            </span>
                        </div>
                        {/* <div className="flex items-center justify-between text-[rgba(255,75,75,1)]">
                            <span>{t("my-points")}</span>
                            <span className="font-medium">
                                -{toArabic(checkoutDetails.points.toFixed(2))} {t(currency)}
                            </span>
                        </div> */}
                        <div className="flex items-center justify-between text-lg font-semibold text-primary">
                            <span>{t("total")}</span>
                            <span className="font-medium">
                                {toArabic(checkoutDetails.total.toFixed(2))}{" "}
                                {t(currency)}
                            </span>
                        </div>
                    </div>
                    <button
                        onClick={checkout}
                        className="mt-20 flex items-center justify-center rounded-lg bg-primary px-6 py-[18px] text-center text-white transition hover:opacity-80"
                    >
                        {loading ? <LoadingSpinner /> : t("complete-order")}
                    </button>
                </div>
            </div>
        </div>
    );
}
